.clientInfo {
  font-size: 12px;
}

.clientInfoAvatar {
  width: 96px;
  height: 96px;
  margin: 0 auto;
  box-shadow: 0 0 0 0 rgba(64, 138, 255, 1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(64, 138, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(64, 138, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(64, 138, 255, 0);
  }
}
