.dropZone {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: var(--ion-color-light);
  border-radius: 10px;
  outline: none;
}

.dropZoneDragInactive {
  border-color: var(--ion-color-light-shade);
}

.dropZoneDragActive {
  border-color: var(--ion-color-primary);
}

.dropZoneImage {
  height: 100%;
  min-height: 0;
  max-height: 200px;
}

.dropZoneImageDragInactive {
  filter: grayscale(100%);
}

.dropZoneImageDragActive {
  animation: swing 1s linear forwards infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
