.noConnection {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.noConnectionConnectingLogo {
  height: 100%;
  max-height: 100px;
  filter: grayscale(100%);
  animation: swing 1s linear forwards infinite;
}
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
