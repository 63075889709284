.userInfo {
  font-size: 12px;
}

.userInfoInactive {
  cursor: pointer;
}

.userInfoActive {
  cursor: not-allowed;
}

.userInfoInactive:hover > .userInfoAvatar {
  filter: brightness(75%);
}

.userInfoAvatar {
  width: 96px;
  height: 96px;
  margin: 0 auto;
  transition: 0.1s;
}
